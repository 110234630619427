<template lang="">
  <div class="banner">
      <div class="container">
        <div class="content-head">
          <el-row :gutter="24" style="margin-right: 0;">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" class="header-left">
              <h1>{{ $t(`Home['stay_connected_people']`) }}</h1>
              <p>{{ $t(`Home['stay_connected_people_description']`) }}</p>
              <div class="download">
                <p style="font-size: 20px; margin-bottom: 10px; font-weight: 500;">{{ $t(`Home['download_mosappOn']`) }}</p>
                <ul>
                  <li v-for="item in downloadList" :key="item.value">
                    <img :src="getAssetsFile(item.src + '.png')" alt="" class="down-img" @click="clickHandle(item)">
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12">
              <div class="image-head">
                <img src="../../assets/image/home/Stay connect with people.png" alt="" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
</template>
<script>
import { getAssetsFile, downloadFile } from '@/utils'

export default {
  data() {
    return {
      getAssetsFile,
      downloadFile,
      downloadList: [
        {
          type: 'IOS',
          value: 'appstore',
          url: 'https://apps.apple.com/cn/app/mosapp/id6446265600',
          src: 'app-store'
        },
        {
          type: 'Android',
          value: 'appandroid',
          url: 'https://oss.metathought.cc/public/mosapp/app/MosApp-Android-Latest.apk',
          src: 'app-android'
        },
        {
          type: 'Windows',
          value: 'windows',
          url: 'https://oss.metathought.cc/public/mosapp/pc/MosApp-Windows-Latest.exe',
          src: 'win-os'
        },
        {
          type: 'MacOS',
          value: 'mac',
          url: 'https://oss.metathought.cc/public/mosapp/pc/MosApp-MacOS-Latest.dmg',
          src: 'mac-os'
        }
      ]
    }
  },
  methods: {
    clickHandle (item) {
      const os = this.getType()
      if (item.value === 'appstore') {
        if (os === 'IOS') {
          window.open('itms-apps://apps.apple.com/cn/app/mosapp/id6446265600')
        } else {
          window.open(item.url)
        }
      } else {
        const fileName = item.url.substring(item.url.lastIndexOf('/') + 1)
        if (os === 'IOS' && (item.value === 'windows' || item.value === 'mac')) {
          // showDialog({ message: t('home.tips') })
        } else {
          downloadFile(item.url, fileName)
        }
      }
    },
    getType () {
      // 判断设备是PC端还是移动端: false PC端 | true 移动端
      const isMobile = () => {
        return !!navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|WebOS|Windows Phone|Phone)/i)
      }
      const isMacOs = () => {
        return /macintosh|mac os x/i.test(navigator.userAgent)
      }
      const isWindows = () => {
        const agent = navigator.userAgent.toLowerCase()
        if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
          return true
        }
        if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
          return true
        }
        return false
      }
      // 判断设备时安卓还是IOS
      const isAndroid = () => {
        return /android/i.test(navigator.userAgent.toLowerCase())
      }
      const isIOS = () => {
        const reg = /iPhone|iPad|iOS|Macintosh/i
        return reg.test(navigator.userAgent.toLowerCase())
      }
      if (isMobile()) {
        if (isAndroid()) return 'Android'
        if (isIOS()) return 'IOS'
      } else {
        if (isMacOs()) return 'MacOS'
        if (isWindows()) return 'Windows'
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .banner {
    position: relative;
    background-color: #E7EEFF99;
    background-position: center;
    height: auto;
    .container {
      color: #ffffff;
      position: relative;
      .content-head {
        h1 {
          color: #000000;
          font-size: 51px;
          font-weight: 900;
          margin-top: 135px;
        }
        p {
          color: #1F1F1F;
          font-size: 17px;
          font-weight: 400px;
          margin-top: 30px;
          line-height: 35px;
        }
        .image-head {
          margin-top: 50px;
          display: flex;
          justify-content: flex-end;
          @media screen and (max-width: 1199px) {
            display: flex;
            justify-content: center;
            margin-top: 0px;
            margin-bottom: 2px;
            margin-bottom: 10px
          }
          @media screen and (max-width: 767px) {
            position: inherit;
            bottom: inherit;
          }
          img {
            width: 650px;
            margin-top: 20px;
            @media screen and (max-width: 1029px) {
              width: 97%;
            }
          }
        }
        .down-img {
          cursor: pointer;
        }
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      .header-left {
        position: relative;
        height: inherit;
      }
    }
    @media screen and (min-width: 767px) {
      .header-left {
        position: relative;
        height: 685px;
      }
    }
    .download {
      @media screen and (min-width: 767px) {
        position: absolute;
        bottom: 55px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 65px;
      }
      ul {
        display: flex;
        // justify-content: center;
        flex-wrap: wrap;
        margin-top: 10px;
        li {
          margin: 0 15px 0 0;
          img {
            width: 180px;
            height: 50px;
          }
        }
        @media screen and (max-width: 1625px) {
          li {
            margin: 0 8px 15px;
            img {
              width: 130px;
              height: 41.6px;
            }
          }
        }
      }
    }
  }
</style>
