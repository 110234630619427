<template lang="">
  <div class="aboutupay">
    <Header />
    <ReliableMessageFlatform />
    <!-- <DownloadMosapp /> -->
  </div>
</template>
<script>
import Header from '../components/home/Header.vue'
import ReliableMessageFlatform from '../components/home/ReliableMessageFlatform.vue'
// import DownloadMosapp from '../components/home/DownloadMosapp.vue'
export default {
  name: 'Home',
  components: {
    Header,
    ReliableMessageFlatform
    // DownloadMosapp
  },
  data() {
    return {
      isPauseVideo: false
    }
  },
  computed: {
  }
}
</script>
<style lang="">
</style>
