<template lang="">
  <div class="container">
    <div class="title-flatform">
      <h1>{{ $t(`Home['secure_reliable_everyone']`) }}</h1>
      <p>{{ $t(`Home['prioritizes_security_privacy']`) }}<br> {{ $t(`Home['privacy_message_delivered']`) }}</p>
    </div>
    <div>
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="12">
          <div style="margin-top: 40px; width: 88%;">
            <div @click="handleTabs(index)" v-for="(list, index) in listFlatfome" :key="index" :class="index === isTabs ? 'list-flatform-tabs active' : 'list-flatform-tabs'">
              <p style="font-size:24px; margin-bottom: 5px;">{{ $t(`Home['${list.title_name}']`) }}</p>
              <p style="font-size:17px;color:#595959">{{ $t(`Home['${list.content_name}']`) }}</p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" ref="animations">
          <div v-for="(list, index) in listFlatfome" :key="index" class="imgFlatform">
            <img v-if="index === isTabs " :src="list.image" :alt="list.title_name" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="card-protecting">
        <el-col :xs="24" :sm="24" :md="12"><img src="../../assets/image//home/protecting.png" alt="" class="protecting"/></el-col>
        <el-col :xs="24" :sm="24" :md="12">
          <div class="cart-title">
            <h1>{{ $t(`Home['protecting_conversations']`) }}<br />{{ $t(`Home['preserving_privacy']`) }}</h1>
            <p style="color:#595959">{{ $t(`Home['we_maintain_fastReliable_performance']`) }}</p><br/><br/>
            <div v-for="(list, index) in listProtectPrivacy" :key="index" class="list-flatform" style="padding-left:0px">
              <div class="list-protecting">
                <img :src="list.icon" />
                <div>
                  <p style="font-size:24px">{{ $t(`Home['${list.title_name}']`) }}</p>
                  <p style="font-size:17px;color:#595959">{{ $t(`Home['${list.content_name}']`) }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-top: 40px; margin-bottom: 40px;">
        <el-col :xs="24" :sm="24" :md="12">
          <div class="cart-title">
            <h1>{{ $t(`Home['we_are_available_mulitpleEnv']`) }}</h1>
            <p style="color:#595959">{{ $t(`Home['available_android_ios']`) }}</p><br/><br/>
            <div v-for="(list, index) in listMultipleStayconnected" :key="index" class="list-flatform" style="padding-left:0px">
              <div class="list-protecting">
                <img :src="list.icon" />
                <div>
                  <p style="font-size:24px;">{{ $t(`Home['${list.title_name}']`) }}</p>
                  <p style="font-size:17px;color:#595959">{{ $t(`Home['${list.content_name}']`) }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12"><img src="../../assets/image//home/available-stay-connected.png" alt="" class="we-avaible"/></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isTabs: 0,
      listFlatfome: [
        {
          title: 'Instant Messaging',
          content: 'Fast, Reliable, Instant Communication and Messages are delivered in real-time, Made Effortless for quick and efficient communication.',
          title_name: 'insta_messaging',
          content_name: 'fast_instant_communication',
          image: require('@/assets/image/home/InstantMessaging.png')
        },
        {
          title: 'Multimedia Sharing',
          content: 'Can share different types of multimedia content including photos, videos, audio messages and documents in a End to end Encryption way.',
          title_name: 'multimedia_sharing',
          content_name: 'canShare_multimedia',
          image: require('@/assets/image/home/Multimedia-Sharing.png')
        },
        {
          title: 'Voice & Video Calling',
          content: 'Safe and smooth conversation you can initiate with unlimited and real-time Voice and Video Call.',
          title_name: 'voiceVideoCall',
          content_name: 'safe_smooth_conversation',
          image: require('@/assets/image/home/Voice-Video-Calling.png')
        },
        {
          title: 'Group Chats',
          content: 'Bringing up-to 10k People Together to Amplify the Communication and Exchange information to Strengthen Connections to collaborate each other in one Room.',
          title_name: 'group_chats',
          content_name: 'bringUpTogether',
          image: require('@/assets/image/home/group-chat.png')
        },
        {
          title: 'Channel',
          content: 'In this channel function you distribute the content across your network seamlessly and can able to relatively understand the information.',
          title_name: 'channel',
          content_name: 'channelDistribute',
          image: require('@/assets/image/home/chanael.png')
        }
      ],
      listProtectPrivacy: [
        {
          icon: require('@/assets/svg/key-encryption.svg'),
          title: 'Double Ratchet algorithm Encryption',
          content: 'Encryption protects the confidentiality of chat conversations by converting the messages into an unreadable format',
          title_name: 'double_ratchet_encryption',
          content_name: 'encryption_protects_conversations'
        },
        {
          icon: require('@/assets/svg/security-comunication.svg'),
          title: 'Secure Communication',
          content: 'Ｗe ensures that your conversations remain private and confidential. With end-to-end encryption',
          title_name: 'secure_communication',
          content_name: 'we_ensure_conversation'
        },
        {
          icon: require('@/assets/svg/build-ur-privacy.svg'),
          title: 'Build for your Privacy',
          content: 'Ｗe build to protect the confidentiality, integrity, and privacy of your conversations',
          title_name: 'build_privacy',
          content_name: 'weBuildConfidential'
        }
      ],
      listMultipleStayconnected: [
        {
          icon: require('@/assets/svg/reach-acces-bility.svg'),
          title: 'Reach and Accessibility',
          content: 'To simplify your experience we are available in different operating systems or devices, such as smartphones, tablets, or desktop computers',
          title_name: 'reach_access',
          content_name: 'toSimplifyExperience'
        },
        {
          icon: require('@/assets/svg/user-convenein.svg'),
          title: 'User Convenience',
          content: 'You can switch between their mobile devices and desktops while enjoying a consistent chat experience',
          title_name: 'userCovenience',
          content_name: 'switchBetwweenMobile'
        },
        {
          icon: require('@/assets/svg/crose-flatform.svg'),
          title: 'Cross-Platform Compatibility',
          content: 'we are supporting one to one desktop and mobile apps can be use in a same time.',
          title_name: 'crossPlatform',
          content_name: 'weAreSupportingDesktop'
        }
      ]
    }
  },
  methods: {
    handleTabs(param) {
      this.isTabs = param
      const children = this.$refs.animations.$el.children
      const selected = children[param]
      for (let i = 0; i <= children.length - 1; i++) {
        const ch = children[i]
        if (i !== param) {
          ch.classList.remove('fadeInUp')
        }
      }
      selected.classList.add('fadeInUp')
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  // position: relative;
  // padding: 20px;
  // @media screen and (max-width: 1650px) {
  //   padding: 0 65px;
  // }
  .we-avaible {
    width:100%; padding-left: 40px;
    @media screen and (max-width: 1450px) {
      padding-left: 0px;
    }
  }
  .card-protecting {
    margin-top: 30px;
    @media screen and (max-width: 1450px) {
      margin-top: 70px;
    }
  }
  .protecting {
    width:100%; padding-right: 40px;
    @media screen and (max-width: 1450px) {
      padding-right: 0px
    }
  }
  .title-flatform {
    text-align: center;
    margin: 30px 0px 45px 0px;
    @media screen and (max-width: 992px) {
      margin: 30px 0px 0px 0px;
    }
    h1 {
      font-size: 38px;
      font-weight: 500;
    }
    p {
      font-size: 20px;
      color: #595959;
    }
  }
  .list-flatform {
    padding-left: 15px;
    margin-bottom: 70px;
    position: relative;
  }
  @media screen and (min-width: 777px) {
    .list-flatform-tabs {
      padding-left: 15px;
      margin-bottom: 30px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90px;
    }
    .list-flatform-tabs::before {
      content: '';
      border-left: 4px solid #c9c9c9;
      border-top: 65px solid #c9c9c9;
      border-radius: 10px;
      height: 10px;
      position: absolute;
      top: 10px;
      left: 0px;
    }
    .active::after {
      content: '';
      border-left: 4px solid #3370FF;
      border-top: 65px solid #3370FF;
      border-radius: 10px;
      height: 10px;
      position: absolute;
      top: 10px;
      left: 0px;
    }
  }
  @media screen and (max-width: 777px) {
    .list-flatform-tabs {
      padding-left: 15px;
      margin-bottom: 30px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
    }
    .list-flatform-tabs::before {
      content: '';
      border-left: 4px solid #c9c9c9;
      border-top: 100px solid #c9c9c9;
      border-radius: 10px;
      height: 10px;
      position: absolute;
      /* top: 0px; */
      left: 0px;
    }
    .active::after {
      content: '';
      border-left: 4px solid #3370FF;
      border-top: 100px solid #3370FF;
      border-radius: 10px;
      height: 10px;
      position: absolute;
      // top: 0px;
      left: 0px;
    }
  }
  .imgFlatform img {
    width: 700px;
    height: 730px;
    object-fit: fill;
    box-shadow: 3px 5px 12px -4px gray;
    border-radius: 50px;
    @media screen and (min-width: 992px) {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
  }

  .imgFlatform {
      width: 100%;
      position: relative;
      text-align: end;
  }
  .fadeInUp{animation:fadeInUp 1s ease backwards;}
  @keyframes fadeInUp{
    0%{transform:translate(0px, 100px); opacity: 0;}
    100%{transform:translate(0px, 0); opacity: 1;}
  }
  .cart-title {
    margin-top: 10px;
    h1 {
      font-size: 34px;
      font-weight: 500;
    }
    p {
      font-size: 20px;
    }
  }
  .list-protecting {
    display: flex;
    align-items: center;
    column-gap: 25px;
    img {
      background-color: #E7EEFF;
      border-radius: 50%;
      padding: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
